import './home.css';
import FotoUserBlack from '../../img/nfe96.png'
import { useState, useEffect } from 'react';
import Compromissos from '../../components/Compromissos/Compromissos.jsx';
// import ChartNfse from './GraficoNfse.jsx';
// import ChartContasPagar from './GraficoContasPagar.jsx';
// import ChartFaturamento from './GraficoFaturamento.jsx';
import NFSe from '../../components/Nfse/Nfse.jsx';
import Emitente from '../../components/Emitente/Emitente.jsx';
import Clientes from '../../components/Clientes/Clientes.jsx';
import Impostos from '../../components/Impostos/Impostos.jsx';
import Despesas from '../../components/Despesas/Despesa.jsx';
import Servicos from '../../components/Servicos/Servicos.jsx';
import Faturamento from '../../components/Faturamento/Faturamento.jsx';
import Usuarios from '../../components/Usuarios/Usuarios.jsx';
import api from "../../api";
import LogoutIcon from '@mui/icons-material/Logout';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import ContasPgar from '../../components/ContasPagar/ContasPagar.jsx'
import Credores from '../../components/Credores/Credores.jsx';
import { AuthContext } from '../../routes.jsx';
import { useContext } from 'react';
import { Grid, Card, CardContent, Typography, Box, Divider } from '@mui/material';
// import { display, fontSize, height, maxHeight } from '@mui/system';
import ChartImpostos from './GraficoImpostos.jsx';

function Home() {
    const navegate = useNavigate();
    const { user } = useContext(AuthContext);

    // Pega a data atual
    const hoje = new Date();
    // nome do mes atual
    const mesAtual = hoje.toLocaleString('pt-BR', { month: 'long' });
    // Mês anterior
    const mesAnterior = new Date(hoje.getFullYear(), hoje.getMonth() - 1);
    const nomeMesAnterior = mesAnterior.toLocaleString('pt-BR', { month: 'long' });
    const doismesAnterior = new Date(mesAnterior.getFullYear(), mesAnterior.getMonth() - 1);
    const nomeMesAnterior2 = doismesAnterior.toLocaleString('pt-BR', { month: 'long' });

    // Primeiro dia do mês atual
    const primeiroDia = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
    // Último dia do mês atual
    const ultimoDia = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0);

    // Formata as datas para o formato YYYY-MM-DD
    const primeiroDiaFormatado = primeiroDia.toISOString().split('T')[0];
    const ultimoDiaFormatado = ultimoDia.toISOString().split('T')[0];


    const [mostrarDivMeio, setMostrarDivMeio] = useState('dashboard');
    const usuarioLogado = localStorage.getItem("usuarioLogado");
    const [ano, setAno] = useState("2024");
    const [emitentes, setEmitentes] = useState("");
    const [selectedEmitente, setSelectedEmitente] = useState(0);
    const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));

    const acesso = localStorage.getItem('sessionChaveAcesso');
    const acessoContador = localStorage.getItem("sessionContador");
    const [totalPagar, setTotalPagar] = useState(0);
    const [totalPagas, setTotalPagas] = useState(0);
    const [totalImpostos, setTotalImpostos] = useState(0);
    const [totalImpostos1, setTotalImpostos1] = useState(0);
    const [totalImpostos2, setTotalImpostos2] = useState(0);
    const [totalReceber, setTotalReceber] = useState(0);
    const [totalNfse, setTotalNfse] = useState(0);
    const [totalNfse1, setTotalNfse1] = useState(0);
    const [totalNfse2, setTotalNfse2] = useState(0);
    const [qtdNfse, setQtdNfse] = useState(0);
    const [qtdNfse1, setQtdNfse1] = useState(0);
    const [qtdNfse2, setQtdNfse2] = useState(0);
    const [totalServicos, setTotalServicos] = useState(0);

    const [totalCliente, setTotalCliente] = useState(0);
    const [mili, setMili] = useState(new Date());

    const [selectedMonth, setSelectedMonth] = useState('Dezembro / 2024');

    const alternarVisibilidade = (valor) => {
        if (valor === 'dashboard') {
            setMili(new Date());
        }
        setMostrarDivMeio(valor);
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function handleReferencia(e) {
        setAno(e.target.value);
        setMili(new Date());
    }

    useEffect(() => {
        (async () => {
            try {
                if (!selectedEmitente) {
                    return;
                }
                const totpagar = await api.get(`/constaPagar/mensal/${hoje.getMonth() + 1}/${hoje.getFullYear()}/A`, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (totpagar.status === 200) {
                    setTotalPagar(totpagar.data[0].total);
                } else {
                    setTotalPagar(0);
                }
                // const totpagas = await api.get(`/constaPagar/mensal/${hoje.getMonth() + 1}/${hoje.getFullYear()}/P`,{
                //     headers: {
                //         'Authorization': `Bearer ${tokenSession}`,
                //     }
                // });                
                // if (totpagas.status === 200) {
                //     setTotalPagas(totpagas.data[0].total);
                // } else {
                //     setTotalPagas(0);
                // }

                const totreceber = await api.get(`/faturamento/mensal/${hoje.getMonth() + 1}/${hoje.getFullYear()}/A`, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (totreceber.status === 200) {
                    setTotalReceber(totreceber.data[0].total);
                } else {
                    setTotalReceber(0.00);
                }
                const totnfse = await api.get(`/nfseMesAtual/${hoje.getFullYear()}/${hoje.getMonth() + 1}/${selectedEmitente}`, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (totnfse?.status === 200) {
                    setTotalNfse(totnfse?.data[0]?.total_mes_atual);
                    setTotalNfse1(totnfse?.data[0]?.total_mes_anterior);
                    setTotalNfse2(totnfse?.data[0]?.total_mes_anterior1);
                    setQtdNfse(totnfse?.data[0]?.count_mes_atual);
                    setQtdNfse1(totnfse?.data[0]?.count_mes_anterior);
                    setQtdNfse2(totnfse?.data[0]?.count_mes_anterior1);
                } else {
                    setTotalNfse(0);
                    setTotalNfse1(0);
                    setTotalNfse2(0);
                    setQtdNfse(0);
                    setQtdNfse1(0);
                    setQtdNfse2(0);
                }
                const totcli = await api.get('/clientes/Ativo/' + selectedEmitente, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }

                });

                setTotalCliente(totcli.data?.length);

                const totServico = await api.get('/servicos/totaliza', {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }

                });

                setTotalServicos(totServico.data?.total);

                const totImpostos = await api.get(`/imposto/ImpostosDashboard/${hoje.getFullYear()}/${hoje.getMonth()}/${selectedEmitente}`, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }

                });

                if (totImpostos?.status === 200) {
                    setTotalImpostos(totImpostos?.data[0]?.total_mes_atual);
                    setTotalImpostos1(totImpostos?.data[0]?.total_mes_anterior);
                    setTotalImpostos2(totImpostos?.data[0]?.total_mes_anterior1);
                } else {
                    setTotalImpostos(0);
                    setTotalImpostos1(0);
                    setTotalImpostos2(0);
                }
            } catch (error) {
                toast.error('Problema ao totalizar as contas codigo:  ' + error.response.status);
            }
        })();
    }, [mili]);

    useEffect(() => {
        if (!tokenSession) {
            navegate('/login');
        }
    }, []);

    // Select emitentes
    useEffect(() => {
        (async () => {
            try {
                if (tokenSession) {
                    const emitente = await api.get("/emitentesAll/" + acessoContador + "/" + acesso, {
                        headers: {
                            'Authorization': `Bearer ${tokenSession}`,
                        }
                    });
                    const newToken = emitente.headers['newtoken'];
                    if (newToken) {
                        localStorage.setItem('sessionToken', newToken);
                        setTokenSession(newToken);
                    }
                    if (emitente.data) {
                        if (emitente.data.length > 0) {
                            setEmitentes(emitente.data);
                            setSelectedEmitente(emitente.data[0].id);
                        }
                    }
                }
            } catch (error) {
                toast.warning('É necessário fazer login.');
            }
        })();
    }, []);


    const styles = {
        container: {
            backgroundColor: "#f0f4f8",
            padding: "20px",
            borderRadius: "10px",
            maxWidth: "600px",
            height: "80%",
            margin: "20px auto",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            textAlign: "center",

        },
        title: {
            fontSize: "1.5rem",
            marginBottom: "40px",
            color: "#333",
        },
        buttonGrid: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
        },
        button: {
            padding: "10px 20px",
            fontSize: "1rem",
            backgroundColor: "#007BFF",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            transition: "background-color 0.3s",
            height: "60px",
            fontSize: "18px"
        },
        buttonHover: {
            backgroundColor: "#0056b3",
        },
    };


    return <div className='home-container'>

        <div className="div-esquerda">
            <div className="user-info">
                <img src={FotoUserBlack} alt="User" className="img-esquerda" />
                <label style={{ color: "#b6dd08" }}>Usuário: {usuarioLogado}</label>
            </div>
            <hr />
            <ul className="nav-esquerda">
                <li onClick={() => alternarVisibilidade('dashboard')}>
                    <img src="https://img.icons8.com/3d-fluency/94/control-panel.png" alt="Dashboard" />
                    Dashboard
                </li>
                <li onClick={() => alternarVisibilidade('emitente')}>
                    <img src="https://img.icons8.com/fluency/48/person-male.png" alt="Emitente" />
                    Emitente
                </li>
                <li onClick={() => alternarVisibilidade('clientes')}>
                    <img src="https://img.icons8.com/fluency/48/client-management.png" alt="Clientes" />
                    Clientes
                </li>
                <li onClick={() => alternarVisibilidade('credores')}>
                    <img src="https://img.icons8.com/external-phatplus-lineal-color-phatplus/64/external-creditor-global-crisis-phatplus-lineal-color-phatplus.png" alt="Credores" />
                    Credores
                </li>
                <li onClick={() => alternarVisibilidade('servicos')}>
                    <img src="https://img.icons8.com/hands/100/service.png" alt="Serviços" />
                    Serviços
                </li>
                <li onClick={() => alternarVisibilidade('usuarios')}>
                    <img src="https://img.icons8.com/stickers/50/group.png" alt="Usuários" />
                    Usuários
                </li>
                <li onClick={() => alternarVisibilidade('faturamento')}>
                    <img src="https://img.icons8.com/color/48/receipt.png" alt="Faturamento" />
                    Faturamento
                </li>
                <li onClick={() => alternarVisibilidade('nfse')}>
                    <img src="https://img.icons8.com/papercut/60/000000/document.png" alt="NFS-e" />
                    NFS-e
                </li>
                <li onClick={() => alternarVisibilidade('impostos')}>
                    <img src="https://img.icons8.com/external-flatarticons-blue-flatarticons/65/external-tax-taxes-flatarticons-blue-flatarticons.png" alt="Impostos" />
                    Impostos
                </li>
                <li onClick={() => alternarVisibilidade('contaspagar')}>
                    <img src="https://img.icons8.com/external-flaticons-flat-flat-icons/64/external-accounts-finance-flaticons-flat-flat-icons.png" alt="Contas a Pagar" />
                    Contas a Pagar
                </li>
            </ul>
            <div className="nav-footer">
                <div className="social-icons">
                    <img src="https://img.icons8.com/offices/30/facebook-new.png" alt="Facebook" />
                    <img src="https://img.icons8.com/cotton/64/whatsapp--v3.png" alt="WhatsApp" />
                    <img src="https://img.icons8.com/color/48/email.png" alt="Email" />
                </div>
                <Button variant="contained" endIcon={<LogoutIcon />} size="small" onClick={() => {
                    localStorage.clear();
                    // setUser({});
                    navegate("/login");
                }}>
                    Logout
                </Button>
            </div>
        </div>

        {mostrarDivMeio === 'dashboard' && <div id='dashboard' className='div-meio'>
            <div className="div-meio-superior">
                <p className='p-select'>Empresa.: </p>
                <select className='sel-emitente'
                    onChange={(e) => setSelectedEmitente(e.target.value)}
                    required
                    value={selectedEmitente ? selectedEmitente : ''}
                >
                    <option value="">Selecione um emitente</option>
                    {
                        Array.isArray(emitentes) && emitentes.length > 0 ? (
                            emitentes.map((emit) => (
                                <option key={emit.id} value={emit.id}>{emit.nome} - {emit.cod_tributario_municipio}</option>
                            ))
                        ) : (
                            <option value="">Nenhum emitente disponível</option>
                        )
                    }
                </select>
                {/* <p className='p-select'>Ano ref.: </p>
                <select className='ref-select'
                    onChange={handleReferencia}
                    value={ano}
                >
                    <option key={1} value="2024">2024</option>
                    <option key={2} value="2023">2023</option>
                </select> */}
                {/* <button className='btn-select'>Filtrar</button> */}
            </div>
            <div className="div-meio-meio-home">
                {/* <div className="nfsex">
                    <ChartFaturamento ano={ano} />
                </div>
                <div className="nfsex">
                    <ChartNfse ano={ano} />
                </div> */}

                <div className="nfsex" style={{ textAlign: 'left', gap: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <img style={{ width: '50px' }} src="https://img.icons8.com/papercut/60/000000/document.png" alt="" />
                        <h3 style={{ marginTop: '15px' }}>Notas fiscais</h3>
                    </div>
                    <hr></hr>
                    <br></br>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '80%', gap: '10px', borderRadius: '10px' }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            margin: 'auto',
                            backgroundColor: '#f0f4f8',
                            borderRadius: '10px'
                        }}>
                            <h3 style={{ color: '#000' }}>{capitalizeFirstLetter(nomeMesAnterior2)}</h3>
                            <br></br>
                            <p style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', color: '#000', fontSize: '12pt' }}>
                                <span style={{ fontSize: '14pt', marginLeft: '5pt', color: 'blue' }}>R$ {totalNfse2 ?? 0.00}</span>
                            </p>
                            <br></br>
                            <p style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', color: '#000', fontSize: '12pt' }}>
                                Qtde : <span style={{ fontSize: '14pt', marginLeft: '10pt', color: 'blue' }}> {qtdNfse2 ?? 0} </span>
                            </p>
                            <br></br>
                            <img
                                style={{ width: '50px', cursor: 'pointer' }} src="https://img.icons8.com/3d-fluency/94/view.png" alt=""
                                onClick={() => alternarVisibilidade('nfse')}
                            />
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            margin: 'auto',
                            backgroundColor: '#f0f4f8',
                            borderRadius: '10px'
                        }}>
                            <h3 style={{ color: '#000' }}>{capitalizeFirstLetter(nomeMesAnterior)}</h3>
                            <br></br>
                            <p style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', color: '#000', fontSize: '12pt' }}>
                                <span style={{ fontSize: '14pt', marginLeft: '5pt', color: 'blue' }}>R$ {totalNfse1 ?? 0}</span>
                            </p>
                            <br></br>
                            <p style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', color: '#000', fontSize: '12pt' }}>
                                Qtde : <span style={{ fontSize: '14pt', marginLeft: '10pt', color: 'blue' }}> {qtdNfse1} </span>
                            </p>
                            <br></br>
                            <img
                                style={{ width: '50px', cursor: 'pointer' }} src="https://img.icons8.com/3d-fluency/94/view.png" alt=""
                                onClick={() => alternarVisibilidade('nfse')}
                            />
                        </div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            margin: 'auto',
                            backgroundColor: '#f0f4f8',
                            borderRadius: '10px'
                        }}>
                            <h3 style={{ color: '#000' }}>{capitalizeFirstLetter(mesAtual)}</h3>
                            <br></br>
                            <p style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', color: '#000', fontSize: '12pt' }}>
                                <span style={{ fontSize: '14pt', marginLeft: '5pt', color: 'blue' }}>R$ {totalNfse ?? 0}</span>
                            </p>
                            <br></br>
                            <p style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', color: '#000', fontSize: '12pt' }}>
                                Qtde : <span style={{ fontSize: '14pt', marginLeft: '10pt', color: 'blue' }}> {qtdNfse} </span>
                            </p>
                            <br></br>
                            <img
                                style={{ width: '50px', cursor: 'pointer' }} src="https://img.icons8.com/3d-fluency/94/view.png" alt=""
                                onClick={() => alternarVisibilidade('nfse')}
                            />
                        </div>
                    </div>
                </div>

                {/*  impostos  */}
                <div className="nfsex">
                    <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                        <img style={{ width: '50px' }} src="https://img.icons8.com/external-flatarticons-blue-flatarticons/65/external-tax-taxes-flatarticons-blue-flatarticons.png" alt="" />
                        <h3 style={{ marginTop: '15px' }}>Impostos</h3>
                    </div>
                    <hr></hr>
                    <br></br>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '80%', gap: '10px', borderRadius: '10px' }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            margin: 'auto',
                            backgroundColor: '#f0f4f8',
                            borderRadius: '10px'
                        }}>
                            <h3 style={{ color: '#000' }}>{capitalizeFirstLetter(nomeMesAnterior2)}</h3>
                            <br></br>
                            <p style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', color: '#000', fontSize: '12pt' }}>
                                <span style={{ fontSize: '14pt', marginLeft: '5pt', color: 'red' }}>R$ {totalImpostos2 ?? 0}</span>
                            </p>
                            <br></br>
                            <p style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', color: '#000', fontSize: '12pt' }}>
                                Qtde : <span style={{ fontSize: '14pt', marginLeft: '10pt', color: 'red' }}> 0 </span>
                            </p>
                            <br></br>
                            <img style={{ width: '50px' }} src="https://img.icons8.com/3d-fluency/94/view.png" alt="" />
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            margin: 'auto',
                            backgroundColor: '#f0f4f8',
                            borderRadius: '10px'
                        }}>
                            <h3 style={{ color: '#000' }}>{capitalizeFirstLetter(nomeMesAnterior)}</h3>
                            <br></br>
                            <p style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', color: '#000', fontSize: '12pt' }}>
                                <span style={{ fontSize: '14pt', marginLeft: '5pt', color: 'red' }}>R$ {totalImpostos1 ?? 0}</span>
                            </p>
                            <br></br>
                            <p style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', color: '#000', fontSize: '12pt' }}>
                                Qtde : <span style={{ fontSize: '14pt', marginLeft: '10pt', color: 'red' }}> 0 </span>
                            </p>
                            <br></br>
                            <img style={{ width: '50px' }} src="https://img.icons8.com/3d-fluency/94/view.png" alt="" />
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            margin: 'auto',
                            backgroundColor: '#f0f4f8',
                            borderRadius: '10px'
                        }}>
                            <h3 style={{ color: '#000' }}>{capitalizeFirstLetter(mesAtual)}</h3>
                            <br></br>
                            <p style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', color: '#000', fontSize: '12pt' }}>
                                <span style={{ fontSize: '14pt', marginLeft: '5pt', color: 'red' }}>R$ {totalImpostos ?? 0}</span>
                            </p>
                            <br></br>
                            <p style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', color: '#000', fontSize: '12pt' }}>
                                Qtde : <span style={{ fontSize: '14pt', marginLeft: '10pt', color: 'red' }}> 0 </span>
                            </p>
                            <br></br>
                            <img style={{ width: '50px' }} src="https://img.icons8.com/3d-fluency/94/view.png" alt="" />
                        </div>
                    </div>
                    {/* <ChartImpostos ano={ano} /> */}


                </div>

            </div>
            <div className="div-meio-baixo">

                <div className='div-x'>
                    {/* <ChartContasPagar ano={ano} /> */}

                    <div style={styles.container}>
                        <h2 style={styles.title}>Operação de câmbio</h2>
                        <div style={styles.buttonGrid}>
                            <button style={styles.button}>Emitir Invoice</button>
                            <button style={styles.button}>Consultar Recebimento</button>
                            <button style={styles.button}>Extrato de conta</button>
                            <button style={styles.button}>Transferência de valores</button>
                        </div>
                    </div>

                </div>

                <div className='div-x'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>
                                Resumo do mês atual
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Total de Contas a Pagar
                                    </Typography>
                                    <Typography variant="h5" color="primary">
                                        R$ {totalPagar}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Total de Contas a Receber
                                    </Typography>
                                    <Typography variant="h5" color="primary">
                                        R$ {totalReceber}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Total de Clientes Ativos
                                    </Typography>
                                    <Typography variant="h5" color="primary">
                                        {totalCliente} - R$ {totalServicos}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Total de NFSe Emitidas
                                    </Typography>
                                    <Typography variant="h5" color="primary">
                                        R$ {totalNfse}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
        }

        {mostrarDivMeio === 'faturamento' && <div className='div-meio'>
            <Faturamento />
        </div>
        }

        {mostrarDivMeio === 'nfse' && <div className='div-meio'>
            <NFSe />
        </div>
        }

        {mostrarDivMeio === 'emitente' && <div className='div-meio'>
            <Emitente />
        </div>
        }

        {mostrarDivMeio === 'usuarios' && <div className='div-meio'>
            <Usuarios />
        </div>
        }

        {mostrarDivMeio === 'clientes' && <div className='div-meio'>
            <Clientes />
        </div>
        }

        {mostrarDivMeio === 'impostos' && <div className='div-meio'>
            <Impostos />
        </div>
        }

        {mostrarDivMeio === 'despesa' && <div className='div-meio'>
            <Despesas />
        </div>
        }

        {mostrarDivMeio === 'servicos' && <div className='div-meio'>
            <Servicos />
        </div>
        }

        {mostrarDivMeio === 'compromissos' && <div className='div-meio'>
            <Compromissos />
        </div>
        }

        {mostrarDivMeio === 'contaspagar' && <div className='div-meio'>
            <ContasPgar />
        </div>
        }
        {mostrarDivMeio === 'credores' && <div className='div-meio'>
            <Credores />
        </div>
        }

    </div>
}

export default Home;