import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem } from "@mui/material";
import './Modal-Loading.css';
import loadingGif from '../../img/loading.gif';

function ModalLoading({ statusModalLoading, titulo, loading }) {

    return (
        <div>

            <Dialog className="d-container" open={statusModalLoading} scroll="paper" fullWidth maxWidth="lg">
                <DialogTitle>{titulo}</DialogTitle>
                <DialogActions>
                    <div>
                        {loading ? <img src={loadingGif} alt="Loading..." /> : (
                            <>
                                <button className="btn-fat-fechar" style={{ marginRight: 5 }} >Fechar</button>
                            </>
                        )}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ModalLoading;