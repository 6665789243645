import './Faturamento.css';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import api from '../../api.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ModalFaturamento from '../Modal/Modal-faturamento.jsx';
import ModalGeracao from '../Modal/Modal-geracao.jsx';
import ModalConfirmacao from '../Modal/Modal-Confirmacao.jsx'
import imgBoleto from '../../img/imgBoleto.png';
import imgNfe from '../../img/nfe48.png';
import { toast } from 'react-toastify';
// import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import Avatar from '@mui/material/Avatar';

const Faturamento = () => {

    const date = new Date();
    const primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const apiRef = useGridApiRef();

    const [selectedId, setSelectedId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openModalGeracao, setOpenModalGeracao] = useState(false);
    const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);
    const [faturamentos, setFaturamentos] = useState([]);
    const [primeiroDiaString, setPrimeiroDiaString] = useState(`${primeiroDia.getFullYear()}-${(primeiroDia.getMonth() + 1).toString().length < 2 ? `0${primeiroDia.getMonth() + 1}` : primeiroDia.getMonth() + 1}-${primeiroDia.getDate().toString().length < 2 ? `0${primeiroDia.getDate()}` : primeiroDia.getDate()}`);
    const [lastDayDate, setLastDayDate] = useState(`${lastDay.getFullYear()}-${(lastDay.getMonth() + 1).toString().length < 2 ? `0${lastDay.getMonth() + 1}` : lastDay.getMonth() + 1}-${lastDay.getDate().toString().length < 2 ? `0${lastDay.getDate()}` : lastDay.getDate()}`);
    const [total, setTotal] = useState(0);
    const [filtro, setFiltro] = useState("0");
    const [operacao, setOperacao] = useState("");
    const filtroStatus = ['Todos', 'Abertos', 'Recebidos', 'Bonificados', 'NFSe Gerada'];
    const [selectedRows, setSelectedRows] = useState([]);
    const [emitentes, setEmitentes] = useState([]);
    // const [clientes, setClientes] = useState([]);
    const [selectedEmitente, setSelectedEmitente] = useState("");
    const [titulo, setTitulo] = useState("");
    const acesso = localStorage.getItem('sessionChaveAcesso');
    const acessoContador = localStorage.getItem("sessionContador");
    const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));

    // Select emitentes e clientes , aqui vai usar no modal tbm
    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/emitentesAll/" + acessoContador + "/" + acesso, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (data) {
                    setEmitentes(data);
                    if (data.length > 0) {
                        setSelectedEmitente(data[0].id);
                    }
                }
            } catch (error) {
                alert(error);
            }
        })();
    }, []);

    const columns = [
        {
            field: 'editar',
            headerName: '',
            width: 60,
            renderCell: (params) => (
                (params.row.status !== "Aberto" || params.row.criado_nfse === 'S') ? (
                    <Avatar src={'https://img.icons8.com/fluency/48/edit-text-file.png'} alt=''
                        className='btn-ativa-pagar'
                        title='Editar'
                        style={{ opacity: 0.3 }}
                    />
                ) : (
                    <Avatar src={'https://img.icons8.com/fluency/48/edit-text-file.png'} alt=''
                        className='btn-ativa-pagar'
                        onClick={() => handleEditarFaturamento(params.id)}
                        title='Editar'
                    />
                )
            )
        },

        { field: 'nome', headerName: 'Cliente', flex: 0.7, maxWidth: 250 },
        { field: 'cnpj', headerName: 'CNPJ/CPF', flex: 0.7, maxWidth: 150 },
        {
            field: 'lancamento',
            headerName: 'Lançamento',
            align: 'center',
            headerAlign: 'center',
            flex: 0.5,
            type: 'date',
            valueGetter: (value) => value && new Date(value)
        },
        {
            field: 'vencimento',
            headerName: 'Vencimento',
            align: 'center',
            headerAlign: 'center',
            flex: 0.5,
            type: 'date',
            valueGetter: (value) => value && new Date(value)
        },
        {
            field: 'valor', headerName: 'Valor', flex: 0.5, type: 'number',
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (value) => {
                return new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                }).format(value);
            }
        },
        { field: 'status', headerName: 'Status', width: 80 },
        { field: 'numero_boleto', headerName: 'Boleto', width: 70 },
        { field: 'numero_nfse', headerName: 'NFSe', width: 70 },
        {
            field: 'boleto',
            headerName: '',
            width: 85,
            renderCell: (params) => (
                params.row.numero_boleto != null ? (
                    <img src={imgBoleto} style={{ width: 50, height: 50, cursor: 'pointer' }} onClick={() => handleConsultarNFSe(params.row.numero_boleto)}></img>
                ) : (
                    <img src={imgBoleto} className='btn-concluir-nfses' style={{ width: 50, height: 50, opacity: 0.1 }}></img>
                )
            )
        },
        {
            field: 'nfse',
            headerName: '',
            width: 85,
            renderCell: (params) => (
                params.row.numero_nfse != null ? (
                    <img src={imgNfe} className='btn-concluir-nfses' style={{ width: 50, height: 50, cursor: 'pointer' }} onClick={() => handleConsultarNFSe(params.row.numero_nfse)} ></img>
                ) : (
                    <img src={imgNfe} className='btn-concluir-nfses' style={{ width: 50, height: 50, opacity: 0.1 }}></img>
                )
            )
        },
        {
            field: 'deletar',
            headerName: '',
            width: 60,
            renderCell: (params) => (
                (params.row.status !== "Aberto" || params.row.criado_nfse === 'S') ? (
                    <Avatar src={'https://img.icons8.com/plasticine/100/filled-trash.png'} alt=''
                        className='btn-ativa-pagar'
                        style={{ opacity: 0.1 }}
                        title='Excluir'
                    />
                ) : (
                    <Avatar src={'https://img.icons8.com/plasticine/100/filled-trash.png'} alt=''
                        className='btn-ativa-pagar'
                        onClick={() => handleOpenModalConfirmacao(params.id, params.row.status)}
                        title='Excluir'
                    />
                )

            )
        }
    ];

    // Select faturamentos
    useEffect(() => {
        if (!selectedEmitente) { return; }
        (async () => {
            try {
                const { data: faturamentos } = await api.get("/faturamentos/" + primeiroDiaString + "/" + lastDayDate + "/Todos/" + selectedEmitente, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (faturamentos) {
                    setFaturamentos(faturamentos);
                    let iptot = 0;
                    faturamentos.map((fat) => {
                        iptot = iptot + parseFloat(fat.valor);
                        return iptot;
                    });
                    let numeroComDecimais = iptot.toFixed(2);
                    let numeroFormatadoComDecimais = numeroComDecimais.toLocaleString('pt-BR');
                    setTotal(numeroFormatadoComDecimais);
                }
            } catch (error) {
                alert(error);
            }
        })();
    }, [setFaturamentos, primeiroDiaString, lastDayDate, selectedEmitente]);

    const handleOpenModal = () => {
        setOperacao('Incluir');
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setOperacao("");
        setTitulo("");
        handleFiltro();
    }

    const handleOpenModalGeracao = () => {
        setOperacao("Geracao");
        setOpenModalGeracao(true);
    }

    const handleOpenModalBoleto = () => {
        if (selectedRows.length > 0) {
            setOperacao("Boleto");
            setOpenModalGeracao(true);
        } else {
            toast.warning('Favor selecionar os registros que você quer emitir boleto.');
        }
    }

    const handleOpenModalNFSe = () => {
        if (selectedRows.length > 0) {
            setOperacao("NFSe");
            setOpenModalGeracao(true);
        } else {
            toast.warning('Favor selecionar os registros que você quer emitir nfs-e.');
        }
    }

    const handleCloseModalGeracao = () => {
        setOpenModalGeracao(false);
        apiRef.current.setRowSelectionModel([]);
        handleFiltro();
    }

    const handleOpenModalConfirmacao = (id, st) => {
        setSelectedId(id);
        setOperacao('Excluir');
        setTitulo('Confirma a exclusão ?');
        setOpenModalConfirmacao(true);
    }

    const handleConsultarNFSe = async (nfse) => {
        try {
            const nfse_id = await api.get('/nfsefat/' + nfse);
            const consulta = await api.get('/nfsePdf/' + nfse_id.data[0].id);
            window.open(consulta.data, '_blank'); // '_blank' abre em uma nova aba        
        } catch (error) {
            toast.error('Problema na rotina: ' + error);
        }

    }

    function handlePrimeiraData(e) {
        const resultArray = e.substring(0, 10) + ' 00:00:00';
        setPrimeiroDiaString(resultArray);
    }

    function handleUltimaData(e) {
        const resultArray = e.substring(0, 10) + ' 00:00:00';
        setLastDayDate(resultArray);
    }

    function handleSetStatus(e) {
        setFiltro(e);
    }

    const handleFiltro = async (e) => {
        let status = "Todos";
        if (filtro === "1") {
            status = "Aberto"
        }
        if (filtro === "2") {
            status = "Recebido"
        }
        if (filtro === "3") {
            status = "Bonificado"
        }
        if (filtro === "4") {
            status = "NFSe"
        }
        try {
            const { data: faturamentos } = await api.get("/faturamentos/" + primeiroDiaString + "/" + lastDayDate + "/" + status + "/" + selectedEmitente, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            if (faturamentos) {
                setFaturamentos(faturamentos);
                let iptot = 0;
                faturamentos.map((fat) => {
                    iptot = iptot + parseFloat(fat.valor);
                    return iptot;
                });
                let numeroComDecimais = iptot.toFixed(2);
                let numeroFormatadoComDecimais = numeroComDecimais.toLocaleString('pt-BR');
                setTotal(numeroFormatadoComDecimais);
                setSelectedId(null);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    function getRowClassName(params) {
        const status = params.row.status; // Suponha que a coluna "status" contenha o status da linha
        if (params.row.criado_nfse === 'S') {
            return 'linha-nfse'; // Classe CSS para nfse gerada
        } else {
            if (status === 'Aberto') {
                return 'linha-aberto'; // Classe CSS para linhas aprovadas
            } else if (status === 'Recebido') {
                return 'linha-recebido'; // Classe CSS para linhas pendentes
            } else {
                return ''; // Classe padrão para outras linhas
            }
        }
    }

    const handleCloseModalConfirmacao = () => {
        setOpenModalConfirmacao(false);
    }

    const handleExcluirFat = async () => {
        try {
            const exclui = await api.delete('/faturamento/' + selectedId);
            toast.success('Registro excluido com sucesso...');
            handleFiltro();
        } catch (error) {
            toast.error('Problema na exclusão..' + error);
        }
    }

    const handleEmitirNFSe = async () => {
        toast.success('NFSe gerada(s) com sucesso.');
        handleFiltro();
    }

    // para pegar os dados da linha inteira digitada
    const handleRowSelection = (selectionModel, api) => {
        const selectedRowsData = selectionModel.map((id) => api.current.getRow(id));
        setSelectedRows(selectedRowsData); // Armazena os dados completos das linhas selecionadas
    };

    const handleEmitente = (id) => {
        setSelectedEmitente(id);
    }

    const handleEditarFaturamento = (id) => {
        try {
            setSelectedId(id);
            setOperacao("Editar");
            setOpenModal(true);
        } catch (error) {
            toast.error(error);
        }
    }

    const handleOpenModalBaixa = () => {
        if (selectedRows.length > 0) {
            setOperacao("Baixa");
            setTitulo('Confirma a baixa dos registros selecionados ?');
            setOpenModalConfirmacao(true);
        } else {
            toast.warning('Favor selecionar os registros que você quer baixar.');
        }
    }

    const handleBaixaFaturamento = () => {
        try {
            toast.success('Baixa efetuada com sucesso.');
            handleFiltro();
        } catch (error) {
            toast.error('Problema na baixa: ' + error);
        }
    }

    return (
        <div className='container-faturamento'>
            <ModalFaturamento statusModal={openModal} onCloseModal={handleCloseModal} operacao={operacao} selectedId={selectedId} emitentes={emitentes}></ModalFaturamento>
            <ModalGeracao statusModal={openModalGeracao} onCloseModalGeracao={handleCloseModalGeracao} operacao={operacao} emitirNFSe={handleEmitirNFSe} registrosSelected={selectedRows} selectedRows={selectedRows} ></ModalGeracao>
            <ModalConfirmacao statusModal={openModalConfirmacao} onCloseModalConfirmacao={handleCloseModalConfirmacao} onExcluirFaturamento={handleExcluirFat} onBaixaFaturamento={handleBaixaFaturamento} titulo={titulo} operacao={operacao} origem='Faturamento' selectedRows={selectedRows}></ModalConfirmacao>
            <div className="container-top-faturamento">
                <div className="column-top-faturamento">
                    <h2 style={{ color: '#fff' }} >Faturamento</h2>
                </div>
                <div className="btn-novo-fat">
                    <button onClick={handleOpenModal}>Novo</button>
                </div>
                <button className='btn-gerar-fat' onClick={handleOpenModalGeracao} style={{ border: "none", width: 150 }} >Gerar Faturamento</button>
                <button className='btn-gerar-fat' onClick={handleOpenModalBoleto} style={{ border: "none", width: 120 }} >Gerar Boletos</button>
                <button className='btn-gerar-fat' onClick={handleOpenModalNFSe} style={{ border: "none", width: 100 }} >Gerar NFS-e</button>
                <button className='btn-gerar-fat' onClick={handleOpenModalBaixa} style={{ border: "none", width: 100 }} >Baixar Boleto</button>
            </div>
            <div className="nf-filtro-fat">
                <h2 style={{ padding: 10, color: '#fff' }}>Filtros: </h2>
                <TextField
                    id="datetime-inicio"
                    label="Inicial"
                    type="date"
                    defaultValue={primeiroDiaString}
                    onChange={(e) => handlePrimeiraData(e.target.value)}
                    variant="filled"
                    className="fat-custom-textfield"
                    InputLabelProps={{
                        shrink: true,
                        style: { color: 'white' }, // Cor do label
                    }}
                    InputProps={{
                        style: { color: 'white' }, // Cor do texto
                    }}
                />
                <span><h3 style={{ padding: 10, color: '#fff' }}>até</h3> </span>
                <TextField
                    id="datetime-final"
                    label="Final"
                    variant="filled"
                    type="date"
                    defaultValue={lastDayDate}
                    onChange={(e) => handleUltimaData(e.target.value)}
                    className="fat-custom-textfield"
                    InputLabelProps={{
                        shrink: true,
                        style: { color: 'white' }, // Cor do label
                    }}
                    InputProps={{
                        style: { color: 'white' }, // Cor do texto
                    }}
                />

                <select className='btn-filtrar-fat' style={{ width: 200, border: 'none' }}
                    onChange={(e) => handleEmitente(e.target.value)}
                    required
                    value={selectedEmitente}
                >
                    <option value="">Selecione um emitente</option>
                    {
                        Array.isArray(emitentes) && emitentes.length > 0 ? (
                            emitentes.map((emit) => (
                                <option key={emit.id} value={emit.id}>{emit.nome} - {emit.cod_tributario_municipio}</option>
                            ))
                        ) : (
                            <option value="">Nenhum emitente disponível</option>
                        )
                    }
                </select>

                <select className='btn-filtrar-fat'
                    onChange={(e) => handleSetStatus(e.target.value)}
                    required
                >
                    {
                        filtroStatus.map((number, index) => (
                            <option key={index} value={index}>{number}</option>
                        ))
                    }
                </select>

                <button className='btn-filtrar-fat' onClick={handleFiltro} >Filtrar</button>

                <div className='div-total-fat'>
                    <h2 style={{ color: '#fff' }}>Total do filtro: R$ {total}</h2>
                </div>
            </div>

            <div className="nf-table-fat">
                <Box sx={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
                    <DataGrid
                        // autoHeight
                        columns={columns}
                        localeText={{
                            footerRowSelected: (count) =>
                                count !== 1 ? `${count.toLocaleString()} linhas selecionadas` : `${count.toLocaleString()} linha selecionada`,
                            footerTotalRows: 'Total de linhas:',
                            MuiTablePagination: {
                                labelRowsPerPage: 'Linhas por página:',
                            },
                        }}
                        checkboxSelection
                        disableRowSelectionOnClick
                        rows={faturamentos ?? []}
                        isRowSelectable={(params) => {
                            // Exemplo de condição: habilitar o checkbox se o valor de uma coluna "status" for diferente de 'Recebido'
                            return params.row.status !== 'Recebido';
                        }}
                        disableColumnResize={true}
                        apiRef={apiRef} // Passando o apiRef para o DataGrid
                        getRowClassName={getRowClassName}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 50]}
                        onRowSelectionModelChange={(selection) => {
                            handleRowSelection(selection, apiRef) // Passando a seleção e apiRef para pegar os dados      
                            // setSelectedRows(selection); // Armazena as linhas selecionadas no estado                        
                        }}
                    />
                </Box>
            </div>
        </div>
    )

}

export default Faturamento;